import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';

const defaultData = {
    content: null,
    isOpen: false
};

export const ModalContext = React.createContext(defaultData);

/**
 * ModalProvider class.
 *
 * @export
 * @class ModalProvider
 * @extends {Component}
 */
export default class ModalProvider extends Component {
    static propTypes = {children: PropTypes.node.isRequired}

    static defaultProps = {}

    /**
     * Creates an instance of ModalProvider.
     *
     * @param {Object} props Component props.
     * @memberof ModalProvider
     */
    constructor(props) {
        super(props);

        this.state = {
            closeModal: this.closeModal,
            content: null,
            isOpen: false,
            openModal: this.openModal
        };
    }

    /**
     * Closes the modal.
     *
     * @memberof ModalProvider
     */
    @autobind
    closeModal() {
        this.setState({
            content: null,
            isOpen: false
        });
    }

    /**
     * Opens the modal with provided content.
     *
     * @param {ReactClass} content Modal content
     * @memberof ModalProvider
     */
    @autobind
    openModal(content) {
        this.setState({
            content,
            isOpen: true
        });
    }

    /**
     * Renders the modal provider and childs.
     *
     * @returns {JSX} The ModalProvider.
     * @memberof ModalProvider
     */
    render() {
        const {closeModal, content, isOpen, openModal} = this.state;
        const {children} = this.props;

        return (
            <ModalContext.Provider
                value={{
                    closeModal,
                    content,
                    isOpen,
                    openModal
                }}
            >
                {children}
            </ModalContext.Provider>
        );
    }
}