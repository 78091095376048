/* eslint-disable react/jsx-filename-extension */
import {Component} from 'react';

import Head from 'next/head';
import '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';

import {GlobalStyle, theme} from 'Utils/globalStyles';
import ModalProvider from 'Utils/ModalContext';

import 'Fonts/fonts';

/**
 * App Component.
 *
 * @export
 * @class App
 * @extends {Component}
 */
export default class App extends Component {
    static propTypes = {
        Component: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func
        ]).isRequired,
        pageProps: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired
    }

    /**
     * Uses the right layout for the right page.
     *
     * @returns {JSX} The Layout component.
     * @memberof App
     */
    chooseLayout() {
        const {Component: PageComponent, pageProps, router} = this.props;

        if (PageComponent.getLayout) {
            return PageComponent.getLayout(router, pageProps, PageComponent);
        }

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <PageComponent {...pageProps} />;
    }

    /**
     * Renders the component.
     *
     * @returns {Component} React Component.
     * @memberof App
     */
    render() {
        return (
            <>
                <Head>
                    <meta content="initial-scale=1.0, width=device-width" name="viewport" />
                    <title>Produktfinder | Placetel.de</title>
                    <meta content="noindex" name="robots" />
                    <link
                        as="font"
                        crossOrigin="true"
                        href="/fonts/Proxima-Nova-Alt-Bold.ttf"
                        rel="preload"
                        type="font/ttf"
                    />
                    <link
                        as="font"
                        crossOrigin="true"
                        href="/fonts/Proxima-Nova-Semibold.ttf"
                        rel="preload"
                        type="font/ttf"
                    />
                    <link
                        as="font"
                        crossOrigin="true"
                        href="/fonts/Proxima-Nova-Alt-Regular.ttf"
                        rel="preload"
                        type="font/ttf"
                    />
                </Head>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                    <ModalProvider>
                        {this.chooseLayout()}
                    </ModalProvider>
                </ThemeProvider>
            </>
        );
    }
}