import {createGlobalStyle} from 'styled-components';

export const theme = {
    awesomegrid: {
        breakpoints: {
            lg: 62,
            md: 48.0625,
            sm: 36,
            xl: 75,
            xs: 1
        },
        columns: {
            lg: 8,
            md: 8,
            sm: 4,
            xl: 12,
            xs: 4
        },
        container: {
            lg: 'full',
            md: 'full',
            sm: 'full',
            xl: 'full',
            xs: 'full'
        },
        gutterWidth: {
            lg: 1.8,
            md: 1.2,
            sm: 1.2,
            xl: 1.8,
            xs: 1.2
        },
        paddingWidth: {
            lg: 1.6,
            md: 1.6,
            sm: 1.6,
            xl: 1.6,
            xs: 1.6
        }
    },
    colors: {
        accordionChevronColor: '#1C9EE2',
        answerActiveBgColor: '#6AB32E',
        answerBgColor: '#1C9EE2',
        answerHoverBgColor: '#0083BF',
        answerTextColor: '#ffffff',
        buttonActiveBgColor: '#6AB32E',
        buttonPrimaryBgColor: '#1C9EE2',
        buttonPrimaryHoverColor: '#0083BE',
        buttonPrimaryTextColor: '#ffffff',
        checkboxActiveBgColor: '#1C9EE2',
        checkboxBgColor: '#ffffff',
        checkboxBorder: '#E2EAED',
        checkboxIconColor: '#ffffff',
        checklistIconColor: '#21C1AE',
        containerBackground: '#ffffff',
        inputBgColor: '#ffffff',
        inputBorderActiveColor: '#1C9EE2',
        inputBorderDefaultColor: '#E2EAED',
        inputBorderErrorColor: '#FF3B3B',
        inputPlaceholderColor: '#9B9B9B',
        inputTextErrorColor: '#FF3B3B',
        lightButtonColor: '#ffffff',
        mobileIndicatorIconColor: '#1C9EE2',
        modalBgColor: '#ffffff',
        modalCloseIconColor: '#9B9B9B',
        modalCloseIconHoverColor: '#1C9EE2',
        pageBackground: '#f4f6f7',
        placeholderColor: 'rgba(255, 255, 255, 0.75)',
        primaryButton: '#1C9EE2',
        progressIndicatorBg: '#E2EAED',
        progressIndicatorLine: '#6AB32D',
        recommendationBadgeBgColor: '#21C1AE',
        recommendationBadgeColor: '#ffffff',
        recommendationBgColor: '#ffffff',
        recommendationBorderColor: '#21C1AE',
        restartIconActiveColor: '#1C9EE2',
        restartIconBgColor: '#ffffff',
        restartTextColor: '#9B9B9B',
        textColorAlternate: '#21C1AE',
        textColorHighlight: '#1C9EE2',
        textColorInfo: '#9B9B9B',
        textColorLight: '#ffffff',
        textColorPrimary: '#222529',
        textColorPrimaryHover: '#0083BE'
    },
    nfqspacer: {
        baseSpacing: 8,
        pxToRemRatio: 16
    }
};

export const GlobalStyle = createGlobalStyle`
    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    html {
        /* font-size: 10px; */
    }

    html, body {
        background-color: ${theme.colors.pageBackground};
        height: 100%;
        margin: 0;
        max-height: 100%;
        min-height: 100%;
        overflow: hidden;
        padding: 0;
        scroll-behavior: smooth;
    }

    #__next {
        background-color: ${theme.colors.pageBackground};
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;